<template>
  <div class="extra-subjects-list">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          class="mt-4"
          ref="extra-subjects-smart-list"
          :endpoint="extraSubjectsEndpoint"
        >
          <template v-slot:list-item="{ item: subject }">
            <v-list-item
              class="non-focused"
            >
              <v-list-item-content>
                <v-list-item-title>{{ subject.name }}</v-list-item-title>
                <!-- <v-list-item-subtitle
                  >{{ classroom.total_students }} Students</v-list-item-subtitle
                > -->
                    <!-- :v-for="room in subject.eligible_rooms" -->

                <v-list-item-subtitle class="mt-2" v-if="subject.eligible_rooms.length">
                  <v-chip
                    small
                    color="primary"
                    class="mr-2"
                    outlined
                    v-for="r in subject.eligible_rooms"
                    :key="r"
                    >
                      <!-- {{ r.sections }} -->
                      {{ allRooms.find(room => room.id == r) ? allRooms.find(room => room.id == r).sections : "" }}
                      <!-- {{ r }} -->
                    </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon class="pr-3 align-self-center text-center">
                <intract-drop-down-menu
                  :options-list="getDropdownOptions(subject)"
                />
              </v-list-item-icon>

            </v-list-item>

            <v-divider class="mx-4"></v-divider> 

          </template>
        </intract-smart-list>

      </v-col>
    </v-row>

    <intract-create-edit-sheet
      persistent
      key="extra-subject-create-edit-sheet"
      class="extra-subject-create-edit-sheet"
      :title="createEditExtraSubjectSheet.editId ? 'Edit Extra Subject' : 'Create Extra Subject'"
      :visible="createEditExtraSubjectSheet.visible"
      @close="
        createEditExtraSubjectSheet.visible = false;
        createEditExtraSubjectSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.subjectViewSet"
      :edit-id="createEditExtraSubjectSheet.editId"
      :data-object="createEditExtraSubjectSheet.obj"
      create-success-message="Extra Subject created successfully!"
      edit-success-message="Extra Subject edited successfully!"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditExtraSubjectSheet.obj = obj)"
    >
    </intract-create-edit-sheet>

    <confirmation-dialog
      :visible="showDeleteExtraSubjectDialog"
      title="Are you sure you want to delete this extra subject?"
      description="Deleting this subject will also delete all the academic material associated with this subject. Proceed with caution."
      @successCallback="deleteExtraSubject"
      @failureCallback="
        showDeleteExtraSubjectDialog = false;
      "
      delete-dialog
    />
    <v-fab-transition>
      <v-btn
        v-if="!isLoading"
        color="primary"
        class="non-focused"
        @click="createEditExtraSubjectSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>
</template>

<script>
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import { mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";

export default {
  name: "ManageExtraSubjects",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],

  components: {
    IntractSmartList,
    IntractCreateEditSheet, 
    IntractDropDownMenu,
  },

  data() {
    return {
      showDeleteExtraSubjectDialog: false,
      createEditExtraSubjectSheet: {
        visible: false,
        editId: null,
        obj: {
          institution: null,
          room: null,
        },
      },
      // selectedExtraSubject: null,
      toBeDeletedExtraSubject: null,
    };
  },

  computed: {
    ...mapGetters([
      "allRooms",
    ]),

    extraSubjectsEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, "room__isnull=true");
      return url;
    },

    formFields() {
      return {
        name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Subject Name",
          required: true,
          md: 12,
          max: 255,
        },
        eligible_rooms: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Eligible Rooms",
          required: false,
          md: 12,
          multiple: true,
          itemValue: "id",
          itemText: "sections",
          items: this.allRooms,
        },
        faculty: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Assigned Teachers",
          required: false,
          md: 12,
          endpoint: this.Helper.addUrlParams(
            this.endpoints.staffViewSet,
            "paginate=false"
          ),
          multiple: true,
          itemValue: "id",
          itemText: "full_name",
          itemSubtitle: "username",
          items: [],
        },
      };
    },
  },

  methods: {
    async onRefresh() {
      this.$refs["extra-subjects-smart-list"].getItems();
    },

    async deleteExtraSubject() {
      this.showDeleteExtraSubjectDialog = false;
      var subjectId = this.toBeDeletedExtraSubject.id;
      this.toBeDeletedExtraSubject = null;
      var url = this.endpoints.subjectViewSet + subjectId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.onRefresh();
        this.showSnackbar({
          title: "Extra Subject has been successfully deleted!",
          type: "success",
        });
      }
    },
    getDropdownOptions(subject) {
      var self = this;
      return [
        {
          value: 1,
          text: "Edit",
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.createEditExtraSubjectSheet.editId = subject.id;
            self.createEditExtraSubjectSheet.visible = true;
          },
        },
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedExtraSubject = subject;
            self.showDeleteExtraSubjectDialog = true;
          },
        },
      ];
    },
  }, 

  created() {
    this.createEditExtraSubjectSheet.obj.institution = this.currentInstitution.id;
    this.onRefresh();
  },

}
</script>

<style>

</style>